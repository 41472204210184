import { apis } from 'src/environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/admin`

    constructor(public http: HttpClient) {
        // code here
    }

    add(params: any): Observable<any> {
        const url = `${this.baseUrl}/payment-setting/add`
        return this.http.post<any>(url, params)
    }

    update(params: any): Observable<any> {
        const url = `${this.baseUrl}/payment-setting/update`
        return this.http.post<any>(url, params)
    }

    getSettingsDetail(params?: any): Observable<any> {
        const url = `${this.baseUrl}/payment-setting/detail`
        return this.http.get<any>(url)
    }
}
