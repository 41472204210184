<h1 class="title">Price Settings</h1>
<app-breadcrums [data]="breadCrum"></app-breadcrums>

<div class="box">
    <form class="ng-untouched ng-pristine ng-invalid" [formGroup]="dataForm" (ngSubmit)="submitForm(dataForm)"
        #f="ngForm" *ngIf="dataStatus; else loader">
        <div class="row">
            <div class="col-md-4 mb-2">
                <label>Single Inspection Fee <span class="mendatory">*</span></label>
                <input type="number" class="cg" formControlName="singleDayInspectionAmount"
                    placeholder="Enter Single Inspection Fee" />
                <div *ngIf="ui.checkErrors(g.singleDayInspectionAmount, f)" class="errors">
                    <p *ngIf="g.singleDayInspectionAmount.errors?.required">Single Inspection Fee is required</p>
                </div>
            </div>

            <div class="col-md-4 mb-2">
                <label>Inspection Package Fee <span class="mendatory">*</span></label>
                <input type="number" class="cg" formControlName="fifteenDayInspectionAmount"
                    placeholder="Enter Inspection Package Fee" />
                <div *ngIf="ui.checkErrors(g.fifteenDayInspectionAmount, f)" class="errors">
                    <p *ngIf="g.fifteenDayInspectionAmount.errors?.required">Inspection Package Fee is required</p>
                </div>
            </div>

            <div class="col-md-4 mb-2">
                <label class="mb-0">Mentor Fee <span class="mendatory">*</span></label>
                <input type="number" class="cg" formControlName="mentorFee" placeholder="Enter Mentor Fee" />
                <div *ngIf="ui.checkErrors(g.mentorFee, f)" class="errors">
                    <p *ngIf="g.mentorFee.errors?.required">Mentor Fee is required</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex justify-content-end align-items-end pr-5 pb-2">
                <div>
                    <button type="button" class="cg warning" (click)="cancel()">Cancel</button>
                    <button type="submit" class="cg primary ms-2" [disabled]="waiting.save"
                        [ngClass]="{ 'in-progress': waiting.save }">
                        Save
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <ng-template #loader>
        <div class="text-center"><img src="/assets/images/rolling-main.svg" alt="" /></div>
    </ng-template>
</div>