import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from './data.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ApiService } from 'src/app/services/api.service'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { factory } from 'typescript'

@Component({
    selector: 'app-price-settings',
    templateUrl: './price-settings.component.html',
    styleUrls: ['./price-settings.component.scss']
})
export class PriceSettingsComponent implements OnInit {
    cs = ConstantsService
    dataForm: FormGroup
    dataStatus = false
    detail: any = []
    waiting: {
        save: boolean
    }
    breadCrum = [
        {
            link: '/admin/price-setting',
            value: 'Price Settings'
        }
    ]
    filters = {
        status: 'Active',
        orderBy: 'name',
        order: 'ASC'
    }
    saveLoading = false
    modalRef: BsModalRef

    constructor(
        public ds: DataService,
        private alert: IAlertService,
        private fb: FormBuilder,
        public route: ActivatedRoute,
        private router: Router,
        public ui: UIHelpers,
        private ac: ActivatedRoute,
        private modal: BsModalService,
        private api: ApiService
    ) {
        this.waiting = {
            save: false
        }

        this.dataForm = this.fb.group({
            id: new FormControl(null),
            mentorFee: new FormControl(null, [Validators.required]),
            singleDayInspectionAmount: new FormControl(null, [Validators.required]),
            fifteenDayInspectionAmount: new FormControl(null, [Validators.required])
        })
    }
    ngOnInit() {
        this.getDetail()
    }
    get g() {
        return this.dataForm.controls
    }

    getDetail() {
        this.ds.getSettingsDetail().subscribe((resp: any) => {
            if (resp.success === true) {
                if (resp.data !== null) {
                    this.detail = resp?.data || []
                    this.dataForm.patchValue(this.detail)
                }
                this.dataStatus = true
            }
        })
    }

    cancel() {
        this.router.navigate(['admin/dashboard'])
    }

    submitForm(f: any): any {
        this.waiting.save = true
        if (this.dataForm.status === 'INVALID') {
            this.alert.error('Please fill-in valid data and try again')
            this.waiting.save = false
            return
        }

        if (
            this.g.singleDayInspectionAmount.value < 0 ||
            this.g.fifteenDayInspectionAmount.value < 0 ||
            this.g.mentorFee.value < 0
        ) {
            this.alert.error('Amount must be positive')
            this.waiting.save = false
            return
        }

        let saveUpdate: any
        this.g.id.value != null
            ? (saveUpdate = this.ds.update(this.dataForm.value))
            : (saveUpdate = this.ds.add(this.dataForm.value))

        saveUpdate.subscribe((resp: any) => {
            this.waiting.save = false
            if (resp.success === true) {
                if (this.g.id.value != null) {
                    this.alert.success('Price Settings Updated Successfully!')
                } else {
                    this.alert.success('Price Settings Saved Successfully!')
                }
                this.dataForm.patchValue(resp.data)
                this.dataForm.untouched
            } else {
                this.alert.error(resp.errors.general as string)
                this.waiting.save = false
                return
            }
        })
    }
}
