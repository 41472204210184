
import { NgModule } from '@angular/core'
import { SharedModule } from 'src/app/website/shared/shared.module'
import { ModalModule } from 'ngx-bootstrap/modal'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { QuillModule } from 'ngx-quill'
import { NgSelectModule } from '@ng-select/ng-select'
import { AdminSharedModule } from '../admin-shared/admin-shared.module'

import { DataService } from './data.service'

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { PriceSettingsComponent } from './price-settings.component'
@NgModule({
    imports: [
        SharedModule,
        AdminSharedModule,
        ModalModule.forRoot(),
        ReactiveFormsModule,
        NgSelectModule,
        LazyLoadImageModule,
        QuillModule.forRoot(),
        TimepickerModule.forRoot(),
        RouterModule.forChild([{ path: '', component: PriceSettingsComponent}])
    ],
    declarations: [PriceSettingsComponent ],
    providers: [DataService]
})
export class PriceSettingsModule {}
